/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#E2B95D',
    secondary: '#81AEB5',
    tertiary: '#5E7775',
    dark: '#212721',
    white: '#FFFFFF',
    light: '#EFEFEF',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#D0E1E8',
    background2: '#9FC1C9',
    background3: '#9BABA9',
    background4: '#485958',
    background5: '#F3E3BE',
    background6: '#DBA435',
  },
  backgroundColors: {
    dark: {
      textColor: '#FFFFFF',
    },
  },
  gradients: {
    primary: 'linear-gradient(to right, #E1BA64, #B75334)',
    secondary: 'linear-gradient(to right, #9FC1C9, #5E7775)',
    tertiary: 'linear-gradient(to right, #B17B80, #DDBA9B)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: 'Vivint Sans Medium, sans-serif',
    },
    subheadings: {
      family: 'Vivint Circular Medium, Helvetica',
    },
    body: {
      family: 'Vivint Circular Book, Helvetica',
    },
  },
  components: {
    Button: {
      rounded: 'max',
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
